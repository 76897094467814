import React from 'react';

function Verse(props: any) {
    return (
        <div>
            <h4>
                {props.reference}
            </h4>
            <p>
                {props.verseText}
            </p>
        </div>
    );
}

export default Verse;