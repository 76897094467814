import React, { useEffect, useState } from 'react';
import Verse from '../mile-marker/Verse';
import axios from 'axios';
import verseMap from '../date-milemarker-mappings.json';
import { Link } from 'react-router-dom';
import { useAppState } from '../App';

function HomePage() {
    const [mileMarker, setMileMarker] = useState<any>();
    const { state } = useAppState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // var thing = findClosestDate(verseMap);
                var thing = verseMap[0]
                console.log("The closest thing is")
                console.log(thing);
                // const response = await axios.get('https://api.buyck.dev/getDebugMileMarker');
                // const response = await axios.get(`https://api.buyck.dev/getMileMarkerViaId?book=${thing.bookId}&milemarker=${thing.bookMileMarkerId}`);
                // const response = await axios.get(`https://api.buyck.dev/getMileMarkerViaId?book=4&milemarker=1`);
                setMileMarker(state.books[3].mileMarkers[0]);
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };
        
        fetchData();
    }, []);

    if (mileMarker === null || mileMarker === undefined){
        return null;
    }

    return (
        <div className="container">
            <div className="row">
            <div className="col-sm-1"></div>
                <div className="col-sm-5">
                    <div className="container pt-2 mt-2">
                        <div className="p-2">
                            {/* Check config. if normal week, display mile markers. If review week, display book's review markers and a link to the review questions. */}
                            <h2>Mile Marker #{mileMarker.number}</h2>
                            <Verse reference={mileMarker.mileMarkerReference} verseText={mileMarker.mileMarkerText} />
                            <Verse reference={mileMarker.extraMileReference} verseText={mileMarker.extraMileText}/>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Link to="/books/4/milemarkers/1">
                                    <button className="btn btn-lg">View in the Handbook</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-5">
                    <div className="d-none d-lg-block circle pt-5 mt-5 mx-auto"></div>
                </div>
            </div>
        </div>
    );
}

const findClosestDate = (configData: any) => {
    // Get the current date and time
    const currentDate = new Date();
  
    // Filter out items with dates that are in the future or equal to the current date
    const validItems = configData.filter((item: any) => new Date(item.date) >= currentDate);
  
    if (validItems.length === 0) {
      // No valid items found
      return null;
    }
  
    // Sort the valid items in descending order based on the date
    validItems.sort((a: any, b: any) => +new Date(b.date) - +new Date(a.date));
  
    // The first item in the sorted array will be the closest date without going over
    return validItems[0];
  };

export default HomePage;